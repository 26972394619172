import _url from "url";
var exports = {};

/**
 * Module dependencies.
 * @private
 */
var url = _url;
var parse = url.parse;
var Url = url.Url;
/**
 * Module exports.
 * @public
 */

exports = parseurl;
exports.original = originalurl;
/**
 * Parse the `req` url with memoization.
 *
 * @param {ServerRequest} req
 * @return {Object}
 * @public
 */

function parseurl(req) {
  var url = req.url;

  if (url === undefined) {
    // URL is undefined
    return undefined;
  }

  var parsed = req._parsedUrl;

  if (fresh(url, parsed)) {
    // Return cached URL parse
    return parsed;
  } // Parse the URL


  parsed = fastparse(url);
  parsed._raw = url;
  return req._parsedUrl = parsed;
}

;
/**
 * Parse the `req` original url with fallback and memoization.
 *
 * @param {ServerRequest} req
 * @return {Object}
 * @public
 */

function originalurl(req) {
  var url = req.originalUrl;

  if (typeof url !== "string") {
    // Fallback
    return parseurl(req);
  }

  var parsed = req._parsedOriginalUrl;

  if (fresh(url, parsed)) {
    // Return cached URL parse
    return parsed;
  } // Parse the URL


  parsed = fastparse(url);
  parsed._raw = url;
  return req._parsedOriginalUrl = parsed;
}

;
/**
 * Parse the `str` url with fast-path short-cut.
 *
 * @param {string} str
 * @return {Object}
 * @private
 */

function fastparse(str) {
  if (typeof str !== "string" || str.charCodeAt(0) !== 47
  /* / */
  ) {
      return parse(str);
    }

  var pathname = str;
  var query = null;
  var search = null; // This takes the regexp from https://github.com/joyent/node/pull/7878
  // Which is /^(\/[^?#\s]*)(\?[^#\s]*)?$/
  // And unrolls it into a for loop

  for (var i = 1; i < str.length; i++) {
    switch (str.charCodeAt(i)) {
      case 63:
        /* ?  */
        if (search === null) {
          pathname = str.substring(0, i);
          query = str.substring(i + 1);
          search = str.substring(i);
        }

        break;

      case 9:
      /* \t */

      case 10:
      /* \n */

      case 12:
      /* \f */

      case 13:
      /* \r */

      case 32:
      /*    */

      case 35:
      /* #  */

      case 160:
      case 65279:
        return parse(str);
    }
  }

  var url = Url !== undefined ? new Url() : {};
  url.path = str;
  url.href = str;
  url.pathname = pathname;

  if (search !== null) {
    url.query = query;
    url.search = search;
  }

  return url;
}
/**
 * Determine if parsed is still fresh for url.
 *
 * @param {string} url
 * @param {object} parsedUrl
 * @return {boolean}
 * @private
 */


function fresh(url, parsedUrl) {
  return typeof parsedUrl === "object" && parsedUrl !== null && (Url === undefined || parsedUrl instanceof Url) && parsedUrl._raw === url;
}

export default exports;
export const original = exports.original;